import './App.css';
import { useState } from "react";
import Importlogo from "./Image/import.png";
import axios from 'axios';
import { FaRegHourglass } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [selectedFile, setSelectedFile] = useState({});
  const [fileData, setFileData] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const changeHandler = (name, id) => (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const fileType = file["type"];
    const validImageTypes = ["application/pdf"];

    if (!validImageTypes.includes(fileType)) {
      document.getElementById(id).value = null;
    } else {
      setSelectedFile({
        ...selectedFile,
        [name]: file,
      });
    }
  };

  const submit = async () => {
    if (!selectedFile.uploadfile) {
      alert("No file selected");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile.uploadfile);

    const toastId = toast.loading("Uploading..."); 

    try {
      let res = await axios.post("https://baithna.tribandtech.com:8085/urology_pdf_extractor", formData);
      if (res.data && res.data.data) {
        setFileData(res.data.data);
        setErrorMessage("");
        toast.update(toastId, { render: "Processed successfully!", type: "success", isLoading: false, autoClose: 3000 });
      } else {
        setFileData(null); 
        setErrorMessage("Wrong file uploaded");
        toast.update(toastId, { render: "Wrong file uploaded", type: "error", isLoading: false, autoClose: 3000 });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorMessage("Error uploading file");
      toast.update(toastId, { render: "Error uploading file", type: "error", isLoading: false, autoClose: 3000 });
    } finally {
      setLoading(false); 
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const renderPrimaryInsurance = (insurance) => {
    const phoneIndex = insurance.indexOf("Phone:");
    const preCertIndex = insurance.indexOf("Pre-Cert:");

    const mainInfo = insurance.substring(0, phoneIndex).trim();
    const phoneInfo = insurance.substring(phoneIndex, preCertIndex).trim();
    const preCertInfo = insurance.substring(preCertIndex).trim();

    return (
      <>
        <div>{mainInfo}</div>
        <div>{phoneInfo}</div>
        <div>{preCertInfo}</div>
      </>
    );
  };
  const renderSecondaryInsurance = (insurance) => {
    const phoneIndex = insurance.indexOf("Phone:");
    const preCertIndex = insurance.indexOf("Pre-Cert:");

    const mainInfo = insurance.substring(0, phoneIndex).trim();
    const phoneInfo = insurance.substring(phoneIndex, preCertIndex).trim();
    const preCertInfo = insurance.substring(preCertIndex).trim();

    return (
      <>
        <div>{mainInfo}</div>
        <div>{phoneInfo}</div>
        <div>{preCertInfo}</div>
      </>
    );
  };
  const renderPharmacies = (insurance) => {
    const phoneIndex = insurance.indexOf("Phone:");
    const preCertIndex = insurance.indexOf("Fax:");

    const mainInfo = insurance.substring(0, phoneIndex).trim();
    const phoneInfo = insurance.substring(phoneIndex, preCertIndex).trim();
    const preCertInfo = insurance.substring(preCertIndex).trim();

    return (
      <>
        <div>{mainInfo}</div>
        <div>{phoneInfo}</div>
        <div>{preCertInfo}</div>
      </>
    );
  };

  return (
    <div className="">
      <ToastContainer />
      <div className='row justify-content-center'>
        <img src={Importlogo} alt="Importlogo" className="d-flex justify-content-center" style={{ width: "100%", height: "90px" }} />
      </div>
      <div className='p-3'>
        <div className='row d-flex justify-content-center mt-2'>
            <div className="d-flex justify-content-center">
              <div className="">
                <div className="row d-flex justify-content-center">
                  <input
                    className='form-control'
                    type="file"
                    id="uploadfile"
                    accept="application/pdf"
                    name="fileName"
                    onChange={changeHandler("uploadfile")}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
            <button
              className="btn-import d-flex justify-content-center mt-1"
              onClick={submit}
              type="submit"
              disabled={loading}
            >
              {loading ? "Submitting..." : "SUBMIT"}
            </button>
            <button
              className="btn-refresh d-flex justify-content-center ms-2 mt-1"
              onClick={refreshPage}
              type="button"
              disabled={loading}
            >
              REFRESH
            </button>
          </div>
        <div className='row justify-content-center mt-2'>
            <div className="import-border1">
              {loading ? (
                <div className='d-flex align-items-center justify-content-center' style={{ height: '100%', minHeight: '400px' }}>
                  <div>
                    <div className='d-flex justify-content-center'>
                      <FaRegHourglass size={50} color='grey' />
                    </div>
                    <div className='text-center' style={{ fontSize: '20px', fontWeight: 500, color: "grey" }}>Loading...</div>
                  </div>
                </div>
              ) : (
                fileData ? (
                  <div style={{ padding: "10px" }}>
                    {fileData?.Flag === "First_pdf" ? (<div>
                    <h2 className='mt-0 mb-0'>PATIENT DEMOGRAPHICS &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {fileData?.Date}</h2><hr></hr>
                    <div className='row'>
                      <div className='col-2'></div>
                      <div className='col-5'></div>
                      <div className='col-5'>
                        <div className='d-flex' style={{ marginTop: "-25px" }}><p style={{marginRight:"32px"}} className='greytext'>Primary Care</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.Primary}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"3px"}} className='greytext'>Primary Care Fax</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.PRIMARY_CARE_FAX}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"58px"}} className='greytext'>Referring</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.REFERRING}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"64px"}} className='greytext'>Provider</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.PROVIDER}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"63px"}} className='greytext'>Location</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.LOCATION}</a></div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className='row'>
                      <div className='col-2'>
                        <h5>PATIENT INFO:</h5>
                      </div>
                      <div className='col-5'>
                        <div className='d-flex' style={{ marginTop: "-12px" }}><p style={{marginRight:"70px"}} className='greytext'>Gender</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.Gender}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"23px"}} className='greytext'>Marital Status</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.Marital_Status}</a></div>
                      </div>
                      <div className='col-5'>
                        <div className='d-flex' style={{ marginTop: "-12px" }}><p style={{marginRight:"85px"}} className='greytext'>Email</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.EMAIL}</a></div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-2'>
                        <h5>CONTACTS:</h5>
                      </div>
                      <div className='col-5'>
                        <div className='d-flex'><a className='textcolor'>{fileData?.CONTACT?fileData?.CONTACT:"None"}</a></div>
                      </div>
                      <div className='col-5'></div>
                    </div>
                    <div className='row'>
                        <div className='d-flex'><a className='textcolor'></a></div>
                    </div>
                    <div className='row'>
                      <div className='col-2'>
                        <h5>INSURANCE:</h5>
                      </div>
                      <div className='col-5'>
                        <div className='d-flex'><p style={{marginRight:"0px"}} className='greytext'>Primary</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{renderPrimaryInsurance(fileData?.Primary_Insurance)}</a></div>
                        <hr style={{ marginTop: "88px" }}></hr>
                      </div>
                      <div className='col-5'>
                        <div className='d-flex'><p style={{marginRight:"70px"}} className='greytext'>Group</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.primary_insurance?.["Group "]}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"15px"}} className='greytext'>Effective Date</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.primary_insurance?.["Effective_Date"]}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"40px"}} className='greytext'>Prior Auth</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.primary_insurance?.["Prior_Auth"]}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"64px"}} className='greytext'>Co-Pay</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.primary_insurance?.["CoPay"]}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"33px"}} className='greytext'>Deductable</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.primary_insurance?.["Deductible"]}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"20px"}} className='greytext'>Out of Pocket Max</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.primary_insurance?.["Out_of_Pocket_Maximum"]}</a></div>
                        <hr></hr>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-2'></div>
                      <div className='col-5'>
                        <div className='d-flex' style={{ marginTop: "-12px" }}><p  className='greytext'>Secondary</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{renderSecondaryInsurance(fileData?.Secondary)}</a></div>
                      </div>
                      <div className='col-5'>
                        <div className='d-flex' style={{ marginTop: "-12px" }}><p style={{marginRight:"15px"}} className='greytext'>Effective Date</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.secondary_insurance?.["Effective_Date"]}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"40px"}} className='greytext'>Prior Auth</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.secondary_insurance?.["Prior_Auth"]}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"64px"}} className='greytext'>Co-Pay</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.secondary_insurance?.["CoPay"]}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"33px"}} className='greytext'>Deductable</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.secondary_insurance?.["Deductible"]}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"20px"}} className='greytext'>Out of Pocket Max</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.secondary_insurance?.["Out_of_Pocket_Maximum"]}</a></div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-2'>
                        <h5>PHYSICIANS:</h5>
                      </div>
                      <div className='col-5'>
                        <div className='d-flex'><p style={{marginRight:"30px"}} className='greytext'>Primary</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.Primary}</a></div>
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"20px"}} className='greytext'>Referring</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.REFERRING}</a></div>                   
                        <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"28px"}} className='greytext'>Treating</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.Treating}</a></div>
                      </div>
                      <div className='col-5'></div>
                    </div>
                    <div className='row'>
                      <div className='col-2'>
                        <h5>PHARMACIES:</h5>
                      </div>
                      <div className='col-5'>
                        <div className='d-flex'><a className='textcolor'>{renderPharmacies(fileData?.PHARMACIES)}</a></div>
                      </div>
                      <div className='col-5'></div>
                    </div>
                    </div>
                    ):<>
                    <div>
                      <h2 className='mt-0 mb-0'>TELEHEALTH VISIT &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {fileData?.Date}</h2><hr></hr>
                      <div className='row'>
                        <div className='col-5 mt-5'>
                          <div className='d-flex' style={{ marginTop: "-25px" }}><p style={{marginRight:"12px"}} className='greytext'>MRN</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.MRN}</a></div>
                          <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"3px"}} className='greytext'>DC</p><a style={{ marginTop: "15px" }} className='greytext me-1'></a><a className='textcolor'></a></div>
                          <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"18px"}} className='greytext'>SSN</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.SSN}</a></div>
                        </div>
                        <div className='col-2'></div>
                        <div className='col-5'>
                          <div className='d-flex' style={{ marginTop: "-25px" }}><p style={{marginRight:"32px"}} className='greytext'>Primary Care</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.PRIMARY_CARE}</a></div>
                          <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"3px"}} className='greytext'>Primary Care Fax</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.PRIMARY_CARE_FAX}</a></div>
                          <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"58px"}} className='greytext'>Referring</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.REFERRING}</a></div>
                          <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"64px"}} className='greytext'>Provider</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.PROVIDER}</a></div>
                          <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"66px"}} className='greytext'>Treating</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.TREATING}</a></div>
                          <div className='d-flex' style={{ marginTop: "-30px" }}><p style={{marginRight:"63px"}} className='greytext'>Location</p><a style={{ marginTop: "15px" }} className='greytext me-1'>:</a><a className='textcolor'>{fileData?.LOCATION}</a></div>
                        </div>
                      </div>
                      <hr></hr>
                      <div className='row'>
                        <div className='col-2'>
                          <h5>CC:</h5>
                        </div>
                        <div className='col-10'>
                          <div className='d-flex'><a className='textcolor'>{fileData?.cc}</a></div>
                        </div>
                        <div className='col-2'>
                          <h5>HPI:</h5>
                        </div>
                        <div className='col-10'>
                          <div className='d-flex'><a className='textcolor'>{fileData?.HPI}</a></div>
                        </div>
                        <div className='col-2'>
                          <h5>CC/HPI:</h5>
                        </div>
                        <div className='col-10'>
                          <div className='d-flex'><a className='textcolor'>{fileData?.CC_HPI}</a></div>
                        </div>
                      </div>
                    </div>
                    </>}
                  </div>
                ) : (
                  <div className='d-flex align-items-center justify-content-center' style={{ height: '100%', minHeight: '400px' }}>
                    <div>
                      {errorMessage ? (
                        <div className='text-center' style={{ fontSize: '20px', fontWeight: 500, color: "red" }}>{errorMessage}</div>
                      ) : (
                        <div>
                          <div className='d-flex justify-content-center'>
                            <FaRegHourglass size={50} color='grey'/>
                          </div>
                          <div className='text-center' style={{ fontSize: '20px', fontWeight: 500, color: "grey" }}>No Data Available.</div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
        </div>
      </div>
    </div>
  );
}

export default App;
